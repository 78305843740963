import {
  GenerativeQuestionAnsweringUrlCreateDto,
  type ConfirmationPhraseUpdateDto,
  GenerateProjectDto,
  type GenerativeQuestionAnsweringDataDto,
  type GenerativeQuestionAnsweringPromptDto,
  type GenerativeQuestionAnsweringTrainFailedNotificationModel,
  type GenerativeQuestionAnsweringTrainStatusDto,
  type GenerativeQuestionAnsweringTrainedNotificationModel,
  type GetCustomDataKeysInput,
  type GetProjectsInput,
  type ProjectCreateUpdateDto,
  type ProjectDto,
  type ProjectFlowsAndVirtualAgentsDto,
  type ProjectImportDto,
  ProjectPublishStatusDto,
  type ProjectWithDetailsDto,
  TimeZoneInfoDtoPagedResultDto,
} from './project.models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ChannelIntegrationDto,
  ServiceIntegrationDto,
} from 'src/app/integrations/proxy/integration.model';
import {
  CustomDataKeyCreateDto,
  CustomDataKeyDto,
  CustomDataKeyUpdateDto,
  ProjectVersionDto,
} from 'src/app/shared/designer/proxy/designer.model';
import { OutboundRulesCreateUpdateDto, OutboundRulesDto } from './outbound/outbound-rules.models';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  apiName = 'Default';
  projectSettingsCancelRequestedSubject = new Subject<void>();
  generativeQuestionAnsweringTrainedSubject =
    new Subject<GenerativeQuestionAnsweringTrainedNotificationModel>();
  generativeQuestionAnsweringTrainFailedSubject =
    new Subject<GenerativeQuestionAnsweringTrainFailedNotificationModel>();

  create = (input: ProjectCreateUpdateDto) =>
    this.restService.request<ProjectCreateUpdateDto, ProjectWithDetailsDto>(
      {
        method: 'POST',
        url: '/api/app/projects',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<void, void>(
      {
        method: 'DELETE',
        url: `/api/app/projects/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<void, ProjectWithDetailsDto>(
      {
        method: 'GET',
        url: `/api/app/projects/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetProjectsInput) =>
    this.restService.request<void, PagedResultDto<ProjectDto>>(
      {
        method: 'GET',
        url: '/api/app/projects',
        params: {
          filterText: input.filterText,
          name: input.name,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getAllList = () =>
    this.restService.request<void, PagedResultDto<ProjectDto>>(
      {
        method: 'GET',
        url: '/api/app/projects',
        params: {
          maxResultCount: 1000,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: ProjectCreateUpdateDto) =>
    this.restService.request<ProjectCreateUpdateDto, ProjectWithDetailsDto>(
      {
        method: 'PUT',
        url: `/api/app/projects/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  import = (input: ProjectImportDto) => {
    const formData: FormData = new FormData();
    formData.append('file', input.file, input.file.name);
    return this.restService.request<FormData, ProjectDto>(
      {
        method: 'POST',
        url: '/api/app/projects/import',
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  export = (versionId: string) =>
    this.restService.request<void, void>(
      {
        method: 'POST',
        url: `/api/app/projectVersions/project-export/${versionId}`,
      },
      { apiName: this.apiName }
    );

  getProjectExport = (reportId: string) =>
    this.restService.request<any, File>(
      {
        method: 'GET',
        url: `/api/app/projectVersions/project-export/${reportId}`,
        responseType: 'blob' as 'json',
      },
      {
        apiName: this.apiName,
        skipHandleError: true,
      }
    );

  getChannelIntegrationList = () =>
    this.restService.request<void, ChannelIntegrationDto[]>(
      {
        method: 'GET',
        url: '/api/app/projects/channel-integrations',
        params: {},
      },
      { apiName: this.apiName }
    );

  getServiceIntegrationList = () =>
    this.restService.request<void, ServiceIntegrationDto[]>(
      {
        method: 'GET',
        url: '/api/app/projects/service-integrations',
        params: {},
      },
      { apiName: this.apiName }
    );

  updateConfirmationPhrases = (projectId: string, input: ConfirmationPhraseUpdateDto[]) =>
    this.restService.request<ConfirmationPhraseUpdateDto[], void>(
      {
        method: 'PUT',
        url: `/api/app/projects/${projectId}/confirmation-phrases`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getCustomDataKeyList = (projectId: string, input: GetCustomDataKeysInput) =>
    this.restService.request<void, PagedResultDto<ProjectDto>>(
      {
        method: 'GET',
        url: `/api/app/projects/${projectId}/custom-data-keys`,
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  createCustomDataKey = (projectId: string, input: CustomDataKeyCreateDto) =>
    this.restService.request<CustomDataKeyCreateDto, CustomDataKeyDto>(
      {
        method: 'POST',
        url: `/api/app/projects/${projectId}/custom-data-keys`,
        body: input,
      },
      { apiName: this.apiName }
    );

  updateCustomDataKey = (projectId: string, keyId: string, input: CustomDataKeyUpdateDto) =>
    this.restService.request<CustomDataKeyUpdateDto, CustomDataKeyDto>(
      {
        method: 'PUT',
        url: `/api/app/projects/${projectId}/custom-data-keys/${keyId}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  checkIfCustomDataKeyUsedInFlows = (projectId: string, keyId: string) =>
    this.restService.request<void, boolean>(
      {
        method: 'GET',
        url: `/api/app/projects/${projectId}/custom-data-keys/${keyId}/check-if-used`,
      },
      { apiName: this.apiName }
    );

  checkIfVoiceBiometryNodeIsUsed = (projectId: string) =>
    this.restService.request<void, boolean>(
      {
        method: 'GET',
        url: `/api/app/projects/${projectId}/voice-biometrics-usage`,
      },
      { apiName: this.apiName }
    );

  deleteCustomDataKey = (projectId: string, keyId: string) =>
    this.restService.request<void, boolean>(
      {
        method: 'DELETE',
        url: `/api/app/projects/${projectId}/custom-data-keys/${keyId}`,
      },
      { apiName: this.apiName }
    );

  getTimeZones = () =>
    this.restService.request<void, TimeZoneInfoDtoPagedResultDto>(
      {
        method: 'GET',
        url: '/api/app/projects/time-zones',
      },
      { apiName: this.apiName }
    );

  getProjectFlowsAndVirtualAgents = (projectId: string) =>
    this.restService.request<void, ProjectFlowsAndVirtualAgentsDto>(
      {
        method: 'GET',
        url: `api/app/projects/${projectId}/project-flows-and-virtual-agents`,
      },
      { apiName: this.apiName }
    );

  getGenerativeQuestionAnsweringData = (projectId: string) =>
    this.restService.request<void, GenerativeQuestionAnsweringDataDto[]>(
      {
        method: 'GET',
        url: `/api/app/generative-question-answering/${projectId}`,
      },
      { apiName: this.apiName }
    );

  createGenerativeQuestionAnsweringFile = (projectId: string, files: File[]) => {
    const formData: FormData = new FormData();

    formData.append('projectId', projectId);
    files.forEach((file) => {
      formData.append('files', file);
    });

    return this.restService.request<FormData, GenerativeQuestionAnsweringDataDto[]>(
      {
        method: 'POST',
        url: `/api/app/generative-question-answering/${projectId}/file`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  createGenerativeQuestionAnsweringUrl = (
    projectId: string,
    input: GenerativeQuestionAnsweringUrlCreateDto
  ) =>
    this.restService.request<
      GenerativeQuestionAnsweringUrlCreateDto,
      GenerativeQuestionAnsweringDataDto[]
    >(
      {
        method: 'POST',
        url: `/api/app/generative-question-answering/${projectId}/url`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deleteGenerativeQuestionAnsweringFile = (projectId: string, id: string) =>
    this.restService.request<void, GenerativeQuestionAnsweringDataDto[]>(
      {
        method: 'DELETE',
        url: `/api/app/generative-question-answering/${projectId}/file/${id}`,
      },
      { apiName: this.apiName }
    );

  deleteGenerativeQuestionAnsweringUrl = (projectId: string, id: string) =>
    this.restService.request<void, GenerativeQuestionAnsweringDataDto[]>(
      {
        method: 'DELETE',
        url: `/api/app/generative-question-answering/${projectId}/url/${id}`,
      },
      { apiName: this.apiName }
    );

  trainGenerativeQuestionAnsweringModel = (projectId: string) => {
    return this.restService.request<void, boolean>(
      {
        method: 'POST',
        url: `/api/app/generative-question-answering/${projectId}/train`,
      },
      { apiName: this.apiName }
    );
  };

  getGenerativeQuestionAnsweringTrainStatus = (projectId: string) =>
    this.restService.request<void, GenerativeQuestionAnsweringTrainStatusDto>(
      {
        method: 'GET',
        url: `/api/app/generative-question-answering/${projectId}/train-status`,
      },
      { apiName: this.apiName }
    );

  downloadGenerativeQuestionAnsweringFile = (projectId: string, fileId: string) =>
    this.restService.request<any, File>(
      {
        method: 'GET',
        url: `/api/app/generative-question-answering/${projectId}/download/${fileId}`,
        responseType: 'blob' as 'json',
      },
      {
        apiName: this.apiName,
        skipHandleError: true,
      }
    );

  getGenerativeQuestionAnsweringPrompt = (projectId: string) =>
    this.restService.request<void, GenerativeQuestionAnsweringPromptDto>(
      {
        method: 'GET',
        url: `/api/app/projects/${projectId}/generative-question-answering-prompt`,
      },
      { apiName: this.apiName }
    );

  updateGenerativeQuestionAnsweringPrompt = (
    id: string,
    input: GenerativeQuestionAnsweringPromptDto
  ) =>
    this.restService.request<
      GenerativeQuestionAnsweringPromptDto,
      GenerativeQuestionAnsweringPromptDto
    >(
      {
        method: 'PUT',
        url: `/api/app/projects/${id}/generative-question-answering-prompt`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getProjectVersion = (projectVersionId: string) =>
    this.restService.request<void, ProjectVersionDto>(
      {
        method: 'GET',
        url: `/api/app/projectVersions/${projectVersionId}`,
      },
      { apiName: this.apiName }
    );

  checkPublishExportStatus = (projectVersionId: string) =>
    this.restService.request<void, ProjectPublishStatusDto>(
      {
        method: 'GET',
        url: `/api/app/projects/${projectVersionId}/publishing-exporting-status`,
      },
      { apiName: this.apiName }
    );

  generateProject = (generateProjectDto: GenerateProjectDto) =>
    this.restService.request<GenerateProjectDto, ProjectVersionDto>(
      {
        method: 'POST',
        url: `/api/app/projects/ai`,
        body: generateProjectDto,
      },
      { apiName: this.apiName }
    );

  getOutboundRules = (projectId: string) =>
    this.restService.request<void, OutboundRulesDto>(
      {
        method: 'GET',
        url: `/api/app/projects/${projectId}/outbound-rules`,
      },
      { apiName: this.apiName }
    );

  updateOutboundRules = (projectId: string, input: OutboundRulesCreateUpdateDto) =>
    this.restService.request<OutboundRulesCreateUpdateDto, OutboundRulesDto>(
      {
        method: 'PUT',
        url: `/api/app/projects/${projectId}/outbound-rules`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
